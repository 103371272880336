<template>
  <div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="费用信息" name="handle">
        <div class="headerbox">
          <el-button type="warning">提交</el-button>
          <el-button>取消</el-button>
        </div>

        <div class="ReceiptForm">
          <el-form
            ref="form"
            label-position="left"
            :model="form"
            label-width="90px"
            class="formbox"
          >
            <el-form-item label="收支批次号">
              <el-input v-model="form.name" class="inputstyle"></el-input>
            </el-form-item>

            <el-form-item label="*收支类型">
              <el-select
                v-model="form.transportvalue"
                class="inputstyle"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in form.transport"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="费用">
              <el-input v-model="form.name" class="inputstyle"></el-input>
            </el-form-item>

            <el-form-item label="冲销单据">
              <el-select
                v-model="form.transportvalue"
                class="inputstyle"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in form.transport"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="冲销单号">
              <el-input v-model="form.name" class="inputstyle"></el-input>
            </el-form-item>
            <el-form-item label="收入备注">
              <el-input
                v-model="form.name"
                type="textarea"
                class="inputstyle"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        //新建异常
        name: "",
        transportvalue: "",
        transport: [
          //运输方式
          {
            value: "选项1",
            label: "运输方式1",
          },
          {
            value: "选项2",
            label: "运输方式2",
          },
        ],
      },
      couriernumber: "", //快递单号查询
      activeName: "handle", //头部切换
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.headerbox {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}

.ReceiptForm {
  min-width: 500px;
  width: 50%;
  padding: 30px 30px 30px 100px;
  box-sizing: border-box;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  .inputstyle {
    width: 70%;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>